
body {
  font-family: 'Raleway', sans-serif;
}
.heading {
  font-weight: 700;
}
.subheading {
  font-weight: 300;
}

